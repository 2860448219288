import { StreamActions } from "@hotwired/turbo"

StreamActions.add_css_class = function() {
    const classes = (this.getAttribute("classes")?.split(" ") || []).filter((c) => c.length > 0)
  
    if (classes.length > 0) {
      this.targetElements.forEach((element) => element.classList.add(...classes))
    } else {
      console.warn(`[TurboPower] no "classes" provided for Turbo Streams operation "add_css_class"`)
    }
}

StreamActions.remove_css_class = function() {
    const classes = (this.getAttribute("classes")?.split(" ") || []).filter((c) => c.length > 0)
  
    if (classes.length > 0) {
      this.targetElements.forEach((element) => element.classList.remove(...classes))
    } else {
      console.warn(`[TurboPower] no "classes" provided for Turbo Streams operation "remove_css_class"`)
    }
}

StreamActions.open_window = function() {
  window.open(this.getAttribute("url"), '_blank')
}

StreamActions.set_attribute = function() {
    const attribute = this.getAttribute("attribute")
    const value = this.getAttribute("value") || ""
  
    if (attribute) {
      this.targetElements.forEach((element) => element.setAttribute(attribute, value))
    } else {
      console.warn(`[TurboPower] no "attribute" provided for Turbo Streams operation "set_attribute"`)
    }
}