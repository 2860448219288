import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
    static targets = [ "dropdown" ]
    connect() {
        this.dropdown = new Choices(this.dropdownTarget, {
            removeItemButton: true,
            fuseOptions: {
            threshold: 0.4
            }
        });
    }
    disconnect() {
        this.dropdown.destroy()
    }
}