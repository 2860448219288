import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [ "tab", "selectedTabField" ]

  switch_tab(event) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("text-dark-blue", "border-b-4", "border-cyan-blue")
      tab.classList.add("text-light-blue")
    });
    event.target.classList.remove("text-light-blue")
    event.target.classList.add("text-dark-blue", "border-b-4", "border-cyan-blue")
    this.selectedTabFieldTarget.value = event.target.dataset.tabsName
  }
}
