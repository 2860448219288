import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
    static targets = [ "employeeDropdown", "employeeNumberField", "dateSelector", "dateField", "notificationToggle" ]
    connect() {
        this.setEmployeeNumberField()
        this.setDateField()
    }
    setEmployeeNumberField() {
        this.employeeNumberFieldTarget.value = this.employeeDropdownTarget.selectedOptions[0].textContent.match(/\b\w\d+\b/);
    }

    setDateField() {
        let calibrationDate = new Date(this.dateSelectorTarget.value) 
        let fullYearCalibrationDate = calibrationDate.getFullYear()
        let shortYearCalibrationDate = fullYearCalibrationDate.toString().substring(2)
        this.dateFieldTarget.value = shortYearCalibrationDate
    }
}