import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "field",
    "scale"
  ]

  selectScale(event) {
    this.scaleTargets.forEach((scale) => {
        scale.classList.remove("border-cyan-blue")
    });
    event.currentTarget.classList.add("border-cyan-blue")
    this.fieldTarget.value = event.currentTarget.id
  }
}