import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
    connect() {
      tippy(document.querySelectorAll('[data-tippy-content]'), {
        placement: "right",
        theme: 'light'
      })
    }
}