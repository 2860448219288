import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

export default class extends Controller {

  static targets = ['canvas']
  static values = {
    data: Object,
  }

  connect () {
    console.log(this.typeValue)
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(element.getContext('2d'), {

      type: this.chartData.type,
      data: this.chartData.data,
      options: this.chartData.options
    })
  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData () {
    if (!this.hasDataValue) {
      console.warn('[stimulus-chartjs] You need to pass data as JSON to see the chart.')
    }

    return this.dataValue
  }

  get chartOptions () {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions () {
    return {}
  }
}