import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
    static targets = [ "accuracyClassDropdown", "maximumLoadUnitField", "maximumLoadUnitFieldWrapper"]

    connect() {
        this.setMaximumLoadUnitField();
    }
    
    setMaximumLoadUnitField() {
        if (this.accuracyClassDropdownTarget.value == "nsw_1" || this.accuracyClassDropdownTarget.value == "nsw_2") {
            this.maximumLoadUnitFieldTarget.value = "gram";
            
            let hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = this.maximumLoadUnitFieldTarget.name;
            hiddenField.value = this.maximumLoadUnitFieldTarget.value;

            this.maximumLoadUnitFieldTarget.form.appendChild(hiddenField);

            this.maximumLoadUnitFieldTarget.disabled = true;
            this.tooltip = tippy(this.maximumLoadUnitFieldWrapperTarget, {
                content: "1 - NSW Gen.Kl. I und 2- NSW Gen.Kl. II benötigen als Einheit gramm",
                placement: "top",
                theme: 'light'
            });
        } else {
            this.maximumLoadUnitFieldTarget.value = "kilogram";
            this.maximumLoadUnitFieldTarget.disabled = false;
            this.tooltip.destroy();

            let hiddenField = this.maximumLoadUnitFieldTarget.form.querySelector(`input[type="hidden"][name="${this.maximumLoadUnitFieldTarget.name}"]`);
            if (hiddenField) {
                hiddenField.remove();
            }
        }    
    }
}
