import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "dateField", "toggle", "toggleWrapper"]

    setToggle() {
        if (this.dateFieldTarget.value == "" ) {
            this.toggleTarget.checked = false
            this.toggleWrapperTarget.classList.add("hidden")
        } else {
            this.toggleTarget.checked = true
            this.toggleWrapperTarget.classList.remove("hidden")
        }    
    }
}