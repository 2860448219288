import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "scroll" ]
    
    connect() {
        let idSelector = this.element.getAttribute("data-pagination-field")
        this.lastPage = this.element.getAttribute("data-last-page")
        this.paginationField = document.getElementById(idSelector)
        this.loading = false
        document.addEventListener("turbo:before-stream-render", () => {
            this.loading = false
        })
    }

    scrollTrigger() {
        var el = this.scrollTarget
        var sc = el.scrollHeight - el.clientHeight - el.scrollTop;
        
        if (sc < 150 && this.loading == false) {
            let currentPage = this.paginationField.value
            if(parseInt(currentPage) < parseInt(this.lastPage)) {
                this.paginationField.value = parseInt(currentPage) + 1
                this.loading = true
                document.querySelector('[data-submit-form-target]').requestSubmit()
            }
        }
    }

    resetPagination() {
        this.scrollTarget.scrollTop = 0
        this.paginationField.value = 1
        this.loading = true
        document.querySelector('[data-submit-form-target]').requestSubmit()
        this.lastPage = this.element.getAttribute("data-last-page")
    }
}
