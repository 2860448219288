// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./turbo_stream_actions"
import "trix"
import "@rails/actiontext"

window.initMap = function(...args) {
 const event = document.createEvent("Events")   
 event.initEvent("google-maps-callback", true, true)
 event.args = args
 window.dispatchEvent(event)
}