import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  submit() {
    this.formTarget.requestSubmit()
  }

  syncValues(e) {
    let fieldName = e.target.getAttribute("data-field-name")
    let value = e.target.getAttribute("data-field-value") || e.target.value
    document.querySelector(`[data-field-name=${fieldName}]`).value = value
    this.formTarget.requestSubmit()
  }

  filter_with_delay(){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }
}
