import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

export default class extends Controller {
  static targets = [
    "container",
  ]
  static values = {
    data: Array,
    selectedLocation: Object
  }

  connect() {
    const currentLocationIcon = L.divIcon({
      iconSize:null,
      html: `
        <div class="relative inline-flex">
          <div class="flex h-4 w-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-sky-500"></span>
          </div>
        </div>
      `
    })

    const aroundCustomerIcon = L.divIcon({
      iconSize:null,
      iconAnchor: [15, 33],
      html: `
        <svg version="1.1" class="h-8" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
          <line style="fill:none;stroke:#556080;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="29" y1="28" x2="29" y2="57"/>
          <circle style="fill:#DD352E;" cx="29" cy="14" r="14"/>
          <circle style="fill:#F76363;" cx="24" cy="10" r="3"/>
        </svg>
      `
    })
    let currentLocationLatitude = this.selectedLocationValue.latitude
    let currentLocationLongitude = this.selectedLocationValue.longitude
    let type = this.selectedLocationValue.type
  
    this.map = L.map(this.containerTarget).setView([currentLocationLatitude, currentLocationLongitude], 13);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);
    this.dataValue.forEach(customer => {
      L.marker([customer.latitude, customer.longitude], {title:customer.name, riseOnHover: true}).bindPopup(
          `<b>${customer.name}</b> ${customer.distance} km<br>
          ${customer.address}<br>
          <div class="mt-1 mb-2 text-gray-600">
            ${customer.number_of_scales} ${customer.number_of_scales == 1 ? "Gerät" : "Geräte"} (${customer.number_of_scales_with_open_calibration_date} offen, ${customer.number_of_scales_with_overdue_calibration_date} überfällig)<br>
            Schwerste Waage: ${Math.round(customer.highest_scale_load / 1000)} kg<br>
          </div>
          <div class="flex justify-between">
            <a href="./customers/${customer.id}" target="_blank">Details</a>
            <a href="https://maps.google.com?daddr=${encodeURI(customer.address)}" target="_blank">Navigieren</a>
          </div>`
        ).addTo(this.map);
    });
    L.marker([currentLocationLatitude, currentLocationLongitude], {icon: type == "current-location" ? currentLocationIcon : aroundCustomerIcon}).addTo(this.map);
  }

  disconnect(){
    this.map.remove()
  }

  onClick(layer) {
    console.log(layer)
  }
}