import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "fromField", "toField", "form" ]

    setFormFields(from, to) {  
        let fromLocalDateString = from.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'});
        let toLocalDateString = to.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'});
    
        this.fromFieldTarget.value = fromLocalDateString;
        this.toFieldTarget.value = toLocalDateString;
        this.formTarget.requestSubmit()
    }

    set_current_calendar_year() {
        let currentYear = new Date().getFullYear();
        let from = new Date(currentYear, 0, 1);
        let to = new Date(currentYear, 11, 31);
        this.setFormFields(from, to);
    }

    set_previous_calendar_year() {
        let previousYear = new Date().getFullYear() - 1;
        let from = new Date(previousYear, 0, 1);
        let to = new Date(previousYear, 11, 31);
        this.setFormFields(from, to);
    }

    set_current_financial_year() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        let financialYearStartYear = currentDate.getMonth() < 3 ? currentYear - 1 : currentYear;
        let financialYearEndYear = currentDate.getMonth() < 3 ? currentYear : currentYear + 1;
        
        let from = new Date(financialYearStartYear, 3, 1);
        let to = new Date(financialYearEndYear, 2, 31);
        this.setFormFields(from, to);
    }

    set_previous_financial_year() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        let financialYearStartYear = currentDate.getMonth() < 3 ? currentYear - 1 : currentYear;
        let financialYearEndYear = currentDate.getMonth() < 3 ? currentYear : currentYear + 1;

        let from = new Date(financialYearStartYear - 1, 3, 1);
        let to = new Date(financialYearEndYear - 1, 2, 31);
        this.setFormFields(from, to);
    }
}