import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    connect() {
        
        if (typeof(google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        const options = {
            componentRestrictions: { country: "at" },
            fields: ["address_components", "geometry"],
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options);
        this.autocomplete.addListener("place_changed", this.placeChanged.bind(this))
    }

    placeChanged() {
        let place = this.autocomplete.getPlace();
        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    console.log(component.long_name)
                    this.street_number = component.long_name;
                    break;
                }

                case "route": {
                    this.street_name = component.long_name;
                    break;
                }

                case "postal_code": {
                    this.postal_code = component.long_name;
                    break;
                }

                case "locality": {
                    this.city = component.long_name;
                    break;
                }

                case "administrative_area_level_2": {
                    this.district = component.long_name;
                    break;
                }

                case "administrative_area_level_1": {
                    this.state = component.long_name;
                    break;
                }
            }
        }
        this.setFormFields()
    }

    setFormFields() {
        document.getElementById("customer_address").value = `${this.street_name} ${this.street_number || ""}`       
        document.getElementById("customer_postal_code").value = this.postal_code
        document.getElementById("customer_city").value = this.city
        document.getElementById("customer_district").value = this.district
        document.getElementById("customer_state").value = this.state
        this.street_name = ""
        this.street_number = ""
        this.postal_code = ""
        this.city = ""
        this.district = ""
        this.state = ""
    }
}