import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "radioButton", "spinner" ]

    connect() {
        if(document.querySelector(`[data-field-name=nearbySearchTypeField]`).value == "") {
            this.getCurrentLocation()
        }
    }

    getCurrentLocation() {
        this.radioButtonTarget.classList.add("hidden")
        this.spinnerTarget.classList.remove("hidden")
        navigator.geolocation.getCurrentPosition(this.submitForm)
    }

    submitForm(location) {
        document.querySelector(`[data-field-name=currentLocationLatField]`).value = location.coords.latitude
        document.querySelector(`[data-field-name=currentLocationLongField]`).value = location.coords.longitude
        document.querySelector(`[data-field-name=nearbySearchTypeField]`).value = "current-location"
        document.getElementById("filter-form").requestSubmit()
    }
}
