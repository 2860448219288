import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "inputField", "selectedItems" ]

    connect() {
        this.selectedItems = this.inputFieldTarget.value.split(',')
        this.modelName = this.element.getAttribute("data-model-name")
    }

    addItem(e) {
        let id = e.currentTarget.id
        let name = e.currentTarget.textContent
        let input = this.inputFieldTarget
        let hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = `${this.modelName}[]`
        hiddenInput.value = id
        let multiselectPill = 
        `
        <div id="${id}" class="text-xs font-normal leading-none bg-white rounded p-1 mr-1 mt-1 mb-1 relative cursor-pointer" data-action="click->form-multiselect#removeItem">
            <div class="w-full opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center bg-white">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
            ${name} 
        </div>
        `
        input.parentNode.insertBefore(hiddenInput, input.nextSibling);
        this.selectedItemsTarget.insertAdjacentHTML("beforeend", multiselectPill)
      
        document.querySelector('[data-submit-form-target]').requestSubmit()
    }

    removeItem(e) {
        let id = e.currentTarget.id
        let hiddenInput = document.querySelector(`input[value="${id}"]`)
        e.currentTarget.remove()
        

        if (hiddenInput) {
            hiddenInput.remove()
            document.querySelector('[data-submit-form-target]').requestSubmit()
        }
    }
}
