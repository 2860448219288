import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "searchField"]

    radioSubmitForm(e) {
        if(document.querySelector(`[data-field-name=nearbySearchCustomerIdField]`).value == "") {
            alert("Bitte wählen Sie zuerst einen Kunden aus")
        } else {
            document.querySelector(`[data-field-name=nearbySearchTypeField]`).value = "around-customer"
            document.getElementById("filter-form").requestSubmit()
        }
    }

    submitForm(e) {
        document.querySelector(`[data-field-name=nearbySearchTypeField]`).value = "around-customer"
        document.querySelector(`[data-field-name=nearbySearchCustomerIdField]`).value = e.target.getAttribute('id')
        document.getElementById("filter-form").requestSubmit()
    }
    
    focusSearchField() {
        let focusSearchField = () => {
            this.searchFieldTarget.focus();
        }
        setTimeout(focusSearchField, 200);
    }
}
